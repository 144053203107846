@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/css?family=Barlow:400,400i,600,700,700i,800;0,900;1');
@font-face {
  font-family: 'CiutadellaRounded-BdIt';
  src: url('/assets/fonts/381841_0_0.eot');
  src: url('/assets/fonts/381841_0_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/381841_0_0.woff2') format('woff2'),
    url('/assets/fonts/381841_0_0.woff') format('woff'), url('/assets/fonts/381841_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CiutadellaRounded-MdIt';
  src: url('/assets/fonts/381841_1_0.eot');
  src: url('/assets/fonts/381841_1_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/381841_1_0.woff2') format('woff2'),
    url('/assets/fonts/381841_1_0.woff') format('woff'), url('/assets/fonts/381841_1_0.ttf') format('truetype');
}

// $custom-typography: mat-typography-config(
//   $font-family: 'CiutadellaRounded-BdIt, monospace',
// );

$font: 'Barlow', Verdana, sans-serif;
$body-font: 'Barlow';
$header-font: 'CiutadellaRounded-BdIt', monospace;
$header-font-medium: 'CiutadellaRounded-MdIt', monospace;

$font-size-small: rem(14);
$font-size-base: rem(16);
$font-size-bold: rem(18);
$font-bold: 700;
