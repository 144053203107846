%block-link {
    @include hide-text;

    z-index: 20;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-indent: -400%;
}

%hide-text {
    @include hide-text;
}

%is--hidden-mobile {
    @include mappy-bp(small) {
        display: inline-block;
    }

    @include mappy-query(mobile) {
        display: none !important;
    }
}

%is--visually-mobile {
    @include mappy-bp(small) {
        display: none;
    }

    @include mappy-query(mobile) {
        display: block !important;
    }
}

%is--hidden-tablet {
    @include mappy-bp(small) {
        display: inline-block;
    }

    @include mappy-query(tablet) {
        display: none !important;
    }
}

%is--hidden {
    display: none !important;
}

/**
 * Visually hide an element
 */
%visually-hidden {
    @include visually-hidden;
}


/**
 * Wrap the visually hidden mixin in a mobile query
 */
%visually-hidden-mobile {
    /**
     * Target mobile devices
     * @query mobile
     */
    @include mappy-query(mobile) {
        @include visually-hidden;
    }
}

%no--underline {
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
