$gutter: 20px;
$mobile-gutter: ($gutter * 0.4);
$grid-columns: 12;
$site-width: 1480px;
$max-width: rem($site-width);
$max-width-small: rem(620);
$container-width: rem(1200);
$border-radius: 9px;

// Spacing map ------------------------------------------------------------- //
$size-base: (
  margin: rem(24),
  padding: rem(18),
  header: rem(30),
);
