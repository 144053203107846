/**
 * Placeholder for a nice aspect ratio wrapper
 *
 * What is aspect ratio?
 * The aspect ratio of an element describes the proportional relationship between its width and its height.
 * Two common video aspect ratios are 4:3 (the universal video format of the 20th century), and 16:9 (universal for
 * HD television and European digital television)
 */
%aspect-ratio {
    overflow: hidden;
    position: relative;

    &::before {
        display: block;
        padding-top: 56.25%;
        content: '';
    }
}

/**
 * Placeholder for a image or children element in an aspect ratio wrapper
 * @note pulled from parent wrapper to prevent double styling or unused styling
 */
%aspect-ratio-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    .no-objectfit & {
        height: auto;
    }

    // IE 9-11 fix for object-fit
    :root &,
    _:-ms-lang(x) & {
      height: auto;
      // width: auto;
      min-width: 100%;
      min-height: 100%;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

}
