@use '@angular/material' as mat;
    .mat-pseudo-checkbox-checked {
        background: color(aqua);
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: mat.get-color-from-palette($md-fstrprimary, 500);
    }

    .mat-form-field {
        .mat-form-field-label {
            color: mat.get-color-from-palette($md-fstrprimary, 500);
        }
    }

    .mat-slide-toggle {
        position: relative;
        .mat-slide-toggle-thumb::after {
            background: color(blue);
        }
        &.mat-checked {
            .mat-slide-toggle-thumb::after {
                background: color(white);
            }
        }
    }

    .mat-slide-toggle.type--edit {
        .mat-slide-toggle-thumb::after {
            content: '';
            mask-image: url('/assets/gfx/edit-icon.svg');
            mask-position: center center;
            mask-repeat: no-repeat;
            mask-size: 50% 50%;
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }

    .mat-slide-toggle.type--user {
        .mat-slide-toggle-thumb {
            color: black;
            &::after {
                content: '';
                mask: url('/assets/gfx/user-icon.svg');
                display: inline-block;
                width: 100%;
                height: 100%;
                mask-size: 70% 70%;
                mask-repeat: no-repeat;
                mask-position: center center;
            }
        }
        .mat-drawer-container {
            // color: transparent;
        }
    }

    .mat-radio-button {
        &.type--filled {
            .mat-radio-container {
                @include size(32px,
                32px);
            }
            .mat-radio-outer-circle {
                width: 100%;
                height: 100%;
                opacity: 0;
            }
            .mat-radio-inner-circle {
                @include size(24px,
                24px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &.type--new {
            .mat-radio-outer-circle {
                @extend .bg--new;
                border-color: color(aqua) !important;
            }
            .mat-radio-inner-circle {
                @extend .bg--new;
                border: 1px solid color(aqua);
            }
            .mat-radio-persistent-ripple,
            .mat-ripple-element {
                background-color: color(aqua) !important;
            }
            &.mat-radio-checked .mat-radio-outer-circle {
                background-color: color(aqua) !important;
                opacity: .2;
            }
        }
        &.type--priority {
            .mat-radio-outer-circle {
                @extend .bg--priority;
                border-color: color(pink) !important;
                // background-color: pink;
            }
            .mat-radio-inner-circle {
                @extend .bg--priority;
            }
            .mat-radio-persistent-ripple,
            .mat-ripple-element {
                background-color: color(pink) !important;
            }
            &.mat-radio-checked .mat-radio-outer-circle {
                opacity: .3;
            }
        }
        &.type--todo {
            .mat-radio-outer-circle {
                @extend .bg--todo;
                border-color: color(aqua) !important;
                // background-color: green;
            }
            .mat-radio-inner-circle {
                @extend .bg--todo;
            }
            .mat-radio-persistent-ripple,
            .mat-ripple-element {
                background-color: color(aqua) !important;
            }
            &.mat-radio-checked .mat-radio-outer-circle {
                opacity: .3;
            }
        }
        &.type--doing {
            .mat-radio-outer-circle {
                @extend .bg--doing;
                border-color: color(aqua, 400) !important;
            }
            .mat-radio-inner-circle {
                @extend .bg--doing;
            }
            .mat-radio-persistent-ripple,
            .mat-ripple-element {
                background-color: color(aqua, 400) !important;
            }
            &.mat-radio-checked .mat-radio-outer-circle {
                opacity: .3;
            }
        }
        &.type--verify {
            .mat-radio-outer-circle {
                @extend .bg--verify;
                border-color: color(yellow) !important;
            }
            .mat-radio-inner-circle {
                @extend .bg--verify;
            }
            .mat-radio-persistent-ripple,
            .mat-ripple-element {
                background-color: color(yellow) !important;
            }
            &.mat-radio-checked .mat-radio-outer-circle {
                opacity: .3;
            }
        }
        &.type--done {
            .mat-radio-outer-circle {
                @extend .bg--done;
                border-color: color(green) !important;
            }
            .mat-radio-inner-circle {
                @extend .bg--done;
            }
            .mat-radio-persistent-ripple,
            .mat-ripple-element {
                background-color: color(green) !important;
            }
            &.mat-radio-checked .mat-radio-outer-circle {
                opacity: .3;
            }
        }
    }
