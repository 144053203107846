.container {
    margin: 0 auto;
    position: relative;
    z-index: 5;
    // max-width: $container-width;
    width: 100%; //for flexbox
    &.type--xs {
        max-width: 500px;
    }
    &.type--s {
        max-width: 620px;
    }
    &.type--m {
        max-width: 830px;
    }
    &.type--l {
        max-width: 1100px;
    }
    &.type--xl {
        max-width: 1300px;
    }
}

.page-section {
    margin-top: rem(92px);
}

@include mappy-query(mobile) {
    .container {
        padding: 0 rem(15);
    }
    .page-section {
        margin-top: rem(110px);
    }
}

@include mappy-bp(small) {
    .container {
        padding: 0 cm(1);
    }
}
