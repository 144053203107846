.input-checkfield {
  color: color(grey, 600);
  margin-bottom: cm(.5);

  input[type='radio'],
  input[type='checkbox'] {
    +label {
      position: relative;
      margin-right: rem(10);
      font-weight: normal;
      font-size: rem(15);
      cursor: pointer;
      padding-left: rem(30);
      // padding-top: .5px;
      +.form-error {
        text-align: left;
      }

      .icon {
        @include abs(50%, null, null, 7px);

        transform: translate(-75%, -70%);
        opacity: 0;
        transition: opacity $animation-properties;
      }

      &:hover::before {
        border-color: color(grey, 600);
      }
    }
    +label::before {
      position: absolute;
      text-align: center;
      top: 0%;
      content: '';
      left: 0;
      width: rem(15);
      height: rem(15);
      background-color: color(white);
      border: 2px solid color(grey, 600);
      border-radius: 2px;
    }

    &:checked+label {
      .icon {opacity: 1; }
      &::before {
        border-color: color(aqua);
        background-color: color(aqua);

        transition: border-color $animation-properties;
      }
    }
  }

  input[type='radio'].error+label,
  input[type='checkbox'].error+label,
  input[type='checkbox'].ng-invalid.ng-touched+label,
  input[type='checkbox'].ng-invalid.ng-touched~span {
    color: color(red);
    &::before {
      border-color: color(red);
    }
  }

  /**
   * [1]
   * Label next to a radio button
   */

  input[type='radio'] {

    +label {

      .icon {
        @include size(12px, 12px);
        min-height: 4px;
        min-height: 4px;
        margin-left: 6px;
        margin-top: 3px;
        fill: color(grey, 600);
      }

      &::before {
        border-radius: 50%;

      }
    }
    &:checked+label {

      &::before {
        // background-color: color(black);

      }
    }
  }

  /**
   * [2]
   * Label next to a *checked* radio button
   */

  input[type='checkbox'] {
    +label {
      .icon {
        @include size(12px, 12px);
        min-height: 4px;
        min-height: 4px;
        margin-left: 6px;
        margin-top: 3px;
        fill: color(white);
      }

      &::before {
        // border-radius: 2px;
      }
    }
  }
}
