/**
 * The form labels
 * @Note[1]: Create radio button styling
 * @Note[2]: Create checkbox styling
 */

 label {
  cursor: pointer;
}

label:has(input.ng-invalid) {
  color: color(red);
}

/**
 * All labels with a required class get a `*` to indicate they are required
 */

label.required::after {
  color: color(green);
  vertical-align: text-top;
  content: ' *';
  &.error {
    color: color(red);
  }
}


/* [1] */


