
button,
input[type=submit] {
  @extend %button-reset;
}

// .button {
//   position: relative;
//   color: color(grey, 500);
//   text-decoration: none;
//   border-radius: rem(0);
//   height: auto;
//   font-family: $font;
//   text-transform: none;
//   padding: .4rem 1.2rem;
//   overflow: hidden;
//   cursor: pointer;
// }



.button-group {
  margin-left: -.4ex;
  padding: cp(.4) 0;

  button,
  .mat-button-base {
    margin-left: .4ex;
  }
}

.filter__status {
  &-user {
    margin-right: 28px;

    &:after {
      position: absolute;
      right: -16px;
      height: 12px;
      top: 27%;
      width: 2px;
      background-color: color(grey, 500);
      opacity: .5;
      content: '';
      transform: translateX(1px);
      z-index: -1;
    }
  }

  &-reset {
    padding: 4px 5px 8px;
    min-width: 10px;
    line-height: 13px;
    margin-left: 20px;
  }
}

.reset-button {
  &:before {
    position: absolute;
    left: -18px;
    height: 12px;
    top: 28%;
    width: 2px;
    background-color: color(grey, 500);
    opacity: .5;
    content: '';
    transform: translateX(1px);
    z-index: -1;
  }
}
