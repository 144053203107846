@charset "UTF-8";
/* Append pseudo-classes to a selector(s).
*
* @argument {list | string} $inputs
*   A selector, or list of selectors, to apply the pseudo-class to.
*
* @argument {pseudo-class} $pseudo [null]
*   The pseudo-class to be appended.
*
* @return {list}
*
* @access private
*/
/**
 * Function to pick an color off a sass map
 *
 * @see ../settings/_colors.scss
 *
 * @param {string} $color [grey]
 * @param {string} $variant [800]
 * @param {string} $alpha [.68]
 *
 * @example .test { background-color: color(grey); } - get the base color of the grey object
 * @example .test { background-color: color(grey, 700); } - get the color variant of the grey object
 * @example .test { background-color: color(grey, 700, .2); } - get the color variant of the grey object in
 *                                                              a rgba with alpha
 * @example .test { background-color: color(grey, $alpha: .68); } - get the color variant of the grey object in
 *                                                                  a rgba with the base color and a alpha
 */
/**
 * Color key base variable
 */
/**
 * Function to create a rgba color off a sass map
 *
 * @see ../settings/_colors.scss
 *
 * @param {string} $flavour [dark]
 * @param {string} $tint [secondary]
 * @param {string} $base-key [base]
 *
 * @example .test { color: font-color(dark, primary); }
 */
/**
 * Remove the unit of a length
 * @param {Number} $number - Number to remove unit from
 * @return {Number} - Unitless number
 */
/**
 * Convert pixels to ems
 * eg. for a relational value of 12px write em(12) when the parent is 16px
 * if the parent is another value say 24px write em(12, 24)
 */
/**
 * Convert pixels to rems
 * eg. for a relational value of 12px write rem(12)
 * Assumes $em-base is the font-size of <html>
 */
/**
 * Size functions off a sass map
 *
 * @see ../settings/_sizes.scss
 */
/*
 * Default size base variable
 */
/**
 * Calc-math base function
 *
 * @param {string} $type
 * @param {string} $size [0]
 *
 * @example calc-math(margin, $size);
 */
/**
 * Margin function
 *
 * @param {string} $size [0]
 *
 * @example cm(1);
 * @return {number} number or rem or em value
 */
/**
 * Padding function
 *
 * @param {string} $size [0]
 *
 * @example cp(1);
 * @return {number} number or rem or em value
 */
@import url("https://fonts.googleapis.com/css?family=Barlow:400,400i,600,700,700i,800;0,900;1");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,400i,600,700,700i,800;0,900;1");
@font-face {
  font-family: "CiutadellaRounded-BdIt";
  src: url("/assets/fonts/381841_0_0.eot");
  src: url("/assets/fonts/381841_0_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/381841_0_0.woff2") format("woff2"), url("/assets/fonts/381841_0_0.woff") format("woff"), url("/assets/fonts/381841_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "CiutadellaRounded-MdIt";
  src: url("/assets/fonts/381841_1_0.eot");
  src: url("/assets/fonts/381841_1_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/381841_1_0.woff2") format("woff2"), url("/assets/fonts/381841_1_0.woff") format("woff"), url("/assets/fonts/381841_1_0.ttf") format("truetype");
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

li {
  list-style-type: none;
}

blockquote,
q {
  quotes: none;
}
blockquote::before, blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  margin: 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/**
 * Mixins
 */
/**
 * Before
 * @param $width - the width of the psuedo element (default is 100%)
 * @param $height - the height of the psuedo element (default is 100%)
 * @param $content - content property for the before element (default is empty)
 * @param $icon - default there is no icon (true, false)
 * @param $display - configure the display property (default is block) ('block', 'inline-block' || '' || 'poep')
 */
/**
 * Simple color mixin to declare background and color values
 *
 * @require {function} color
 * @require {map} $colors
 *
 * @param {string} $bg-key [gray] - select color key from $colors map
 * @param {string} $bg-variant [500] - select color variant from $colors map
 * @param {string} $color-key [white] - select color key from $colors map
 * @param {string} $color-variant [500] - select color variant from $colors map
 */
/**
 * Base clearfix mixin
 */
/**
 * Clearfix on pseudo element mixin
 */
/**
 * Flexbox grid
 * @param {number} $gutter - unitless size between the columns
 * @param {string} $selector - selector off the children elements (as string, '.CLASSNAME', [data attr]),
 * @param {number} $column-align - property value of justify content,
 * @param {number} $columns-desktop - How many columns needed on desktop,
 * @param {number} $columns-tablet - How many columns needed on tablet,
 * @param {number} $columns-mobile - How many columns needed on mobile,
 * @param {number} $columns-mobile-landscape - How many columns needed on mobile but with an orientation of landscape
 */
/**
 * Default variable settings
 */
/**
 * Flex-group mixin
 * [1] if column align value is space-around there is not left of right margin needed
 * [2] @content directive included
 * [3] Interpolate children selector for customization
 */
/**
 * Grid Mixins
 * home of mixins grid-container and grid-column
 * exception for the grid-column-width function to keep it up together
 */
/**
 * Default variable settings
 */
/**
 * Grid column width calculation
 * @param {number} $columns [0] - amount of columns needed for the result
 * @param {number} $amount [$grid-columns-amount] - amount of columns drawn in design
 * @return {number} calculate width
 */
/**
 * Grid container
 * @param {number} $gutter [$grid-columns-gutter] - unitless size between columns in design
 * @param {number} $container-width [$site-width] calculate percentage margin with container width
 *
 * @example
 *    .grid-container {
 *        @include grid-container;
 *    }
 */
/**
 * Grid column
 * @param {number} $columns [0] - amount of columns needed for the result
 * @param {number} $gutter-left [$grid-columns-gutter] - workaround to affect the padding-left
 * @param {number} $gutter-right [$grid-columns-gutter] - workaround to affect the padding-right
 * @param {number} $amount [$grid-columns-amount] - amount of columns drawn in design
 * @param {boolean} $workaround [false] - set !important flag
 * @param {string} $container-width calculate margin and widht adhv parent container width
 * @require {function} grid-column-width - calculate width of the column
 *
 * @example
 *    .grid-column-1 {
 *        @include grid-column(3);
 *    }
 */
/**
 * Media query extended (or)
 * @param {list} $mq-values - space separated list of media queries
 * @require {list} $mappy-query - default sass map from mappy-breakpoints
 * @require {function} implode - function used to glue strings together, from mappy-breakpoints
 */
/**
 * An inline panel which collapses on mobile devices
 */
/**
 * The two states of the inline (collapsable) panel title
 */
/**
 * Separator above the inline content
 */
/**
 * Before
 * @param $width - the width of the psuedo element (default is 100%)
 * @param $height - the height of the psuedo element (default is 100%)
 * @param $content - content property for the before element (default is empty)
 * @param $icon - default there is no icon (true, false)
 * @param $display - configure the display property (default is block) ('block', 'inline-block' || '' || 'poep')
 */
/*
@mixin text-overflow($lines: 3, $max-height: em(75)) {
    overflow: hidden;
    display: -webkit-box;
    max-height: $max-height;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}
*/
/**
 * Mixin for vertical centering elements in the middle
 *
 * @note no params needed
 * @example .test { @include vertical-align; }
 */
.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 34px;
  font-weight: 600;
  line-height: 32px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Barlow;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Barlow;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Barlow, monospace;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Barlow, monospace;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Barlow, monospace;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: Barlow, monospace;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Barlow, monospace;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Barlow, monospace;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: Barlow, monospace;
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: Barlow, monospace;
}

.mat-calendar {
  font-family: Barlow, monospace;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 16px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: Barlow, monospace;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Barlow, monospace;
}

.mat-step-label {
  font-size: 16px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 16px;
}

.mat-step-label-selected {
  font-size: 16px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: Barlow, monospace;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}

.mat-option {
  font-family: Barlow, monospace;
  font-size: 16px;
}

.mat-optgroup-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Barlow, monospace;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Barlow, monospace;
  font-size: 16px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Barlow, monospace));
  font-size: var(--mdc-typography-button-font-size, 16px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Barlow, monospace;
}

.mat-card-title {
  font-size: 34px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

.mat-tooltip {
  font-family: Barlow, monospace;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Barlow, monospace;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Barlow, monospace;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Barlow, monospace;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: CiutadellaRounded-BdIt;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 16px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Barlow, monospace;
}

.mat-tab-group {
  font-family: Barlow, monospace;
}

.mat-tab-label, .mat-tab-link {
  font-family: Barlow, monospace;
  font-size: 16px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Barlow, monospace;
}

.mat-slider-thumb-label-text {
  font-family: Barlow, monospace;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Barlow, monospace;
  font-size: 16px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Barlow, monospace;
}

.mat-list-option {
  font-family: Barlow, monospace;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-subheader {
  font-family: Barlow, monospace;
  font-size: 16px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Barlow, monospace;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Barlow, monospace;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Barlow, monospace;
  font-size: 16px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Barlow, monospace;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 16px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/* For use in src/lib/core/theming/_palette.scss */
.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3c859a;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #cf522d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3c859a;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cf522d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3c859a;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #cf522d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3c859a;
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #cf522d;
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3c859a;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #cf522d;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3c859a;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #cf522d;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3c859a;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #cf522d;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3c859a;
  --mat-mdc-button-ripple-color: rgba(60, 133, 154, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #cf522d;
  --mat-mdc-button-ripple-color: rgba(207, 82, 45, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3c859a;
  --mat-mdc-button-ripple-color: rgba(60, 133, 154, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #cf522d;
  --mat-mdc-button-ripple-color: rgba(207, 82, 45, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3c859a;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #cf522d;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3c859a;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cf522d;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3c859a;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3c859a;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f4f7fd;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #cf522d;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #cf522d;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f4f7fd;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #cf522d;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #cf522d;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f4f7fd;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f4f7fd;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #f4f7fd;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-progress-bar-background {
  fill: #c6dbe4;
}

.mat-progress-bar-buffer {
  background-color: #c6dbe4;
}

.mat-progress-bar-fill::after {
  background-color: #3c859a;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ebcec9;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ebcec9;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cf522d;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f4cacb;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f4cacb;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3c859a;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #cf522d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3c859a;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #cf522d;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3c859a;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cf522d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #cf522d;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3c859a;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cf522d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3c859a;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cf522d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3c859a;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cf522d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3c859a;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cf522d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #f4f7fd;
}

.mat-checkbox-checkmark-path {
  stroke: #f4f7fd !important;
}

.mat-checkbox-mixedmark {
  background-color: #f4f7fd;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3c859a;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cf522d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3c859a;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cf522d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3c859a;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cf522d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cf522d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(207, 82, 45, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cf522d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3c859a;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(60, 133, 154, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3c859a;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3c859a;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3c859a;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cf522d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cf522d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3c859a;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(60, 133, 154, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cf522d;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(207, 82, 45, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 218, 225, 0.3);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3c859a;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(241, 203, 192, 0.3);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cf522d;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 218, 225, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3c859a;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(241, 203, 192, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cf522d;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #cf522d;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3c859a;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #cf522d;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b5b7bb;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(60, 133, 154, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(60, 133, 154, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(60, 133, 154, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3c859a;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(60, 133, 154, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(60, 133, 154, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(60, 133, 154, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(207, 82, 45, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(207, 82, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(207, 82, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cf522d;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(207, 82, 45, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(207, 82, 45, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(207, 82, 45, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3c859a;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #cf522d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: #3c859a;
}
.mat-icon.mat-accent {
  color: #cf522d;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-drawer-container {
  background-color: #f4f7fd;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3c859a;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cf522d;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3c859a;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #cf522d;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-pseudo-checkbox-checked {
  background: #3c859a;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #3c859a;
}

.mat-form-field .mat-form-field-label {
  color: #3c859a;
}

.mat-slide-toggle {
  position: relative;
}
.mat-slide-toggle .mat-slide-toggle-thumb::after {
  background: #328da3;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb::after {
  background: #ffffff;
}

.mat-slide-toggle.type--edit .mat-slide-toggle-thumb::after {
  content: "";
  mask-image: url("/assets/gfx/edit-icon.svg");
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: 50% 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.mat-slide-toggle.type--user .mat-slide-toggle-thumb {
  color: black;
}
.mat-slide-toggle.type--user .mat-slide-toggle-thumb::after {
  content: "";
  mask: url("/assets/gfx/user-icon.svg");
  display: inline-block;
  width: 100%;
  height: 100%;
  mask-size: 70% 70%;
  mask-repeat: no-repeat;
  mask-position: center center;
}
.mat-radio-button.type--filled .mat-radio-container {
  width: 32px;
  height: 32px;
}
.mat-radio-button.type--filled .mat-radio-outer-circle {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.mat-radio-button.type--filled .mat-radio-inner-circle {
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mat-radio-button.type--new .mat-radio-outer-circle {
  border-color: #3c859a !important;
}
.mat-radio-button.type--new .mat-radio-inner-circle {
  border: 1px solid #3c859a;
}
.mat-radio-button.type--new .mat-radio-persistent-ripple,
.mat-radio-button.type--new .mat-ripple-element {
  background-color: #3c859a !important;
}
.mat-radio-button.type--new.mat-radio-checked .mat-radio-outer-circle {
  background-color: #3c859a !important;
  opacity: 0.2;
}
.mat-radio-button.type--priority .mat-radio-outer-circle {
  border-color: #f53d6f !important;
}
.mat-radio-button.type--priority .mat-radio-persistent-ripple,
.mat-radio-button.type--priority .mat-ripple-element {
  background-color: #f53d6f !important;
}
.mat-radio-button.type--priority.mat-radio-checked .mat-radio-outer-circle {
  opacity: 0.3;
}
.mat-radio-button.type--todo .mat-radio-outer-circle {
  border-color: #3c859a !important;
}
.mat-radio-button.type--todo .mat-radio-persistent-ripple,
.mat-radio-button.type--todo .mat-ripple-element {
  background-color: #3c859a !important;
}
.mat-radio-button.type--todo.mat-radio-checked .mat-radio-outer-circle {
  opacity: 0.3;
}
.mat-radio-button.type--doing .mat-radio-outer-circle {
  border-color: #afdce3 !important;
}
.mat-radio-button.type--doing .mat-radio-persistent-ripple,
.mat-radio-button.type--doing .mat-ripple-element {
  background-color: #afdce3 !important;
}
.mat-radio-button.type--doing.mat-radio-checked .mat-radio-outer-circle {
  opacity: 0.3;
}
.mat-radio-button.type--verify .mat-radio-outer-circle {
  border-color: #fce648 !important;
}
.mat-radio-button.type--verify .mat-radio-persistent-ripple,
.mat-radio-button.type--verify .mat-ripple-element {
  background-color: #fce648 !important;
}
.mat-radio-button.type--verify.mat-radio-checked .mat-radio-outer-circle {
  opacity: 0.3;
}
.mat-radio-button.type--done .mat-radio-outer-circle {
  border-color: #abc71a !important;
}
.mat-radio-button.type--done .mat-radio-persistent-ripple,
.mat-radio-button.type--done .mat-ripple-element {
  background-color: #abc71a !important;
}
.mat-radio-button.type--done.mat-radio-checked .mat-radio-outer-circle {
  opacity: 0.3;
}

.mat-tooltip {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8);
}

.mat-snack-bar-container {
  background-color: #3c859a;
}
.mat-snack-bar-container.is--error {
  background-color: #cf522d;
}

.in-progress__list-item.cdk-drag-preview {
  border-radius: 4.5px;
  box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.2);
  transition: border 0.2s ease-in-out;
}

.in-progress__list-item.cdk-drag-animating {
  transition: transform 0.2s ease-in-out;
}

.mat-color-picker {
  position: relative;
}
.mat-color-picker__button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #328da3;
}
.mat-color-picker__example {
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  transform: translateY(-50%);
}
.mat-color-picker .colorPicker {
  padding-left: 20px;
}

.color-picker {
  box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.2);
}

/**
 * Placeholder for a nice aspect ratio wrapper
 *
 * What is aspect ratio?
 * The aspect ratio of an element describes the proportional relationship between its width and its height.
 * Two common video aspect ratios are 4:3 (the universal video format of the 20th century), and 16:9 (universal for
 * HD television and European digital television)
 */
/**
 * Placeholder for a image or children element in an aspect ratio wrapper
 * @note pulled from parent wrapper to prevent double styling or unused styling
 */
.app__toolbar__user__avatar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.no-objectfit .app__toolbar__user__avatar img, .app__toolbar__user__avatar .no-objectfit img {
  height: auto;
}
:root .app__toolbar__user__avatar img, _:-ms-lang(x) .app__toolbar__user__avatar img, .app__toolbar__user__avatar _:-ms-lang(x) img {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/**
 * Clearfix placeholder
 */
/**
 * Clearfix placeholder on an :after element
 */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "CiutadellaRounded-BdIt", monospace;
  line-height: 1;
  transition: opacity 0.3s ease-in;
  margin-bottom: 1ex;
  font-weight: 700;
  text-transform: uppercase;
}

.h1 {
  font-size: 3.8125rem;
}
@media all and (max-width: 47.8125em) {
  .h1 {
    font-size: 2.8125rem;
  }
}

.h2 {
  font-size: 3rem;
}
@media all and (max-width: 47.8125em) {
  .h2 {
    font-size: 2.25rem;
  }
}

.h3 {
  font-size: 2.4375rem;
}
@media all and (max-width: 47.8125em) {
  .h3 {
    font-size: 1.8125rem;
  }
}

.h4 {
  font-size: 1.9375rem;
}
@media all and (max-width: 47.8125em) {
  .h4 {
    font-size: 1.4375rem;
  }
}

.h5, .rte blockquote, .block__text blockquote {
  font-size: 1.5625rem;
}
@media all and (max-width: 47.8125em) {
  .h5, .rte blockquote, .block__text blockquote {
    font-size: 1.1875rem;
  }
}

.h6 {
  font-size: 1.25rem;
}

/**
* Default input styling
*
* [1] Remove IE clear icon 'X'
*/
.select, [type=checkbox], [type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], input:not([type]), textarea,
input[type=file] {
  display: inline-block;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  will-change: background-color;
  font-size: 1rem;
}
.select::placeholder, [type=checkbox]::placeholder, [type=color]::placeholder, [type=date]::placeholder, [type=datetime]::placeholder, [type=datetime-local]::placeholder, [type=email]::placeholder, [type=month]::placeholder, [type=number]::placeholder, [type=password]::placeholder, [type=search]::placeholder, [type=tel]::placeholder, [type=text]::placeholder, [type=time]::placeholder, [type=url]::placeholder, [type=week]::placeholder, input:not([type])::placeholder, textarea::placeholder,
input[type=file]::placeholder {
  color: #000000;
  opacity: 0.5;
  font-size: 0.875rem;
  font-weight: 300;
  font-style: italic;
  text-transform: none;
}
.select:active, [type=checkbox]:active, [type=color]:active, [type=date]:active, [type=datetime]:active, [type=datetime-local]:active, [type=email]:active, [type=month]:active, [type=number]:active, [type=password]:active, [type=search]:active, [type=tel]:active, [type=text]:active, [type=time]:active, [type=url]:active, [type=week]:active, input:active:not([type]), textarea:active,
input[type=file]:active, .select:focus, [type=checkbox]:focus, [type=color]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=email]:focus, [type=month]:focus, [type=number]:focus, [type=password]:focus, [type=search]:focus, [type=tel]:focus, [type=text]:focus, [type=time]:focus, [type=url]:focus, [type=week]:focus, input:focus:not([type]), textarea:focus,
input[type=file]:focus {
  outline: none;
}
.select::-ms-clear, [type=checkbox]::-ms-clear, [type=color]::-ms-clear, [type=date]::-ms-clear, [type=datetime]::-ms-clear, [type=datetime-local]::-ms-clear, [type=email]::-ms-clear, [type=month]::-ms-clear, [type=number]::-ms-clear, [type=password]::-ms-clear, [type=search]::-ms-clear, [type=tel]::-ms-clear, [type=text]::-ms-clear, [type=time]::-ms-clear, [type=url]::-ms-clear, [type=week]::-ms-clear, input:not([type])::-ms-clear, textarea::-ms-clear,
input[type=file]::-ms-clear {
  display: none;
}

.select {
  border: 1px solid #757575;
  background-color: #ffffff;
  color: #757575;
  border-radius: 9px;
}
.select:focus, .select:active {
  border-color: #757575;
  color: #757575;
}

/*
* Default input error colours
*/
[type=color].error, [type=date].error, [type=datetime].error, [type=datetime-local].error, [type=email].error, [type=month].error, [type=number].error, [type=password].error, [type=search].error, [type=tel].error, [type=text].error, [type=time].error, [type=url].error, [type=week].error, input:not([type]).error, textarea.error,
input[type=file].error, .ng-invalid.select:not(.ng-pristine) {
  border-color: #cf522d;
}

/*
* Input hidden placeholder
*/
[type=radio], [type=checkbox] {
  display: none;
}

@media all and (max-width: 47.8125em) {
  .rte, .block__text {
    font-size: 15px;
  }
}
.rte a, .block__text a {
  text-decoration: underline;
}
.rte iframe, .block__text iframe,
.rte object,
.block__text object,
.rte embed,
.block__text embed {
  width: 100%;
  height: auto;
  min-height: 500px;
}
.rte .entry-content img, .block__text .entry-content img {
  margin: 0 0 24px;
  max-width: 740px;
}
@media all and (max-width: 47.8125em) {
  .rte p, .block__text p,
  .rte ul,
  .block__text ul,
  .rte ol,
  .block__text ol,
  .rte dl,
  .block__text dl,
  .rte table,
  .block__text table,
  .rte blockquote,
  .block__text blockquote {
    margin-bottom: 1ex;
  }
}
@media all and (min-width: 47.875em) {
  .rte p, .block__text p,
  .rte ul,
  .block__text ul,
  .rte ol,
  .block__text ol,
  .rte dl,
  .block__text dl,
  .rte table,
  .block__text table,
  .rte blockquote,
  .block__text blockquote {
    margin-bottom: 1ex;
  }
}
.rte em, .block__text em,
.rte i,
.block__text i {
  font-style: italic;
}
.rte blockquote, .block__text blockquote {
  position: relative;
  margin: 1ex auto;
  max-width: 500px;
  font-weight: 100;
  text-align: center;
}
.rte blockquote::before, .block__text blockquote::before {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 5em;
  font-weight: 400;
  content: "“";
  transform: translateX(-50%);
}
@media all and (max-width: 47.8125em) {
  .rte blockquote, .block__text blockquote {
    padding: 40px 1.125rem 0;
  }
}
@media all and (min-width: 47.875em) {
  .rte blockquote, .block__text blockquote {
    padding: 50px 1.125rem 0.5625rem;
  }
}
.rte ul, .block__text ul,
.rte ol,
.block__text ol {
  margin-left: 3ex;
}
.rte ul li, .block__text ul li,
.rte ol li,
.block__text ol li {
  margin-bottom: 0;
}
.rte ul li.ql-indent-1, .block__text ul li.ql-indent-1,
.rte ol li.ql-indent-1,
.block__text ol li.ql-indent-1 {
  margin-left: 25px;
}
.rte ul li.ql-indent-2, .block__text ul li.ql-indent-2,
.rte ol li.ql-indent-2,
.block__text ol li.ql-indent-2 {
  margin-left: 50px;
}
.rte ul li.ql-indent-3, .block__text ul li.ql-indent-3,
.rte ol li.ql-indent-3,
.block__text ol li.ql-indent-3 {
  margin-left: 75px;
}
.rte ul li.ql-indent-4, .block__text ul li.ql-indent-4,
.rte ol li.ql-indent-4,
.block__text ol li.ql-indent-4 {
  margin-left: 100px;
}
.rte ul > li, .block__text ul > li {
  list-style: disc;
}
.rte ol, .block__text ol {
  counter-reset: li;
}
.rte ol li, .block__text ol li {
  position: relative;
  list-style-type: none;
}
.rte ol li::before, .block__text ol li::before {
  position: absolute;
  top: 0px;
  left: -3ex;
  font-size: 0.875em;
  font-weight: 700;
  content: counter(li) ".";
  counter-increment: li;
}
.rte dl dt, .block__text dl dt {
  font-weight: 700;
}
.rte ul.results, .block__text ul.results {
  margin: 0;
}
.rte ul.results > li, .block__text ul.results > li {
  list-style: none;
}
.rte table, .block__text table {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  border: 0;
}

.block-link {
  overflow: hidden;
  text-indent: -400%;
  white-space: nowrap;
  z-index: 20;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-indent: -400%;
}

/**
 * Visually hide an element
 */
/**
 * Wrap the visually hidden mixin in a mobile query
 */
[type=submit],
[type=button], button,
input[type=submit] {
  overflow: visible;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  border: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: button; /* for input */
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none; /* for button */
}

.login .login__form__field button .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-group {
  margin-left: -0.4ex;
  padding: 0.45rem 0;
}
.button-group button,
.button-group .mat-button-base {
  margin-left: 0.4ex;
}

.filter__status-user {
  margin-right: 28px;
}
.filter__status-user:after {
  position: absolute;
  right: -16px;
  height: 12px;
  top: 27%;
  width: 2px;
  background-color: #757575;
  opacity: 0.5;
  content: "";
  transform: translateX(1px);
  z-index: -1;
}
.filter__status-reset {
  padding: 4px 5px 8px;
  min-width: 10px;
  line-height: 13px;
  margin-left: 20px;
}

.reset-button:before {
  position: absolute;
  left: -18px;
  height: 12px;
  top: 28%;
  width: 2px;
  background-color: #757575;
  opacity: 0.5;
  content: "";
  transform: translateX(1px);
  z-index: -1;
}

.icon {
  position: relative;
  display: inline-block;
  min-width: 14px;
  min-height: 14px;
}
.icon-svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: inherit;
}

.panel {
  background: #fff;
  padding: 25px;
  border: solid 1px rgba(151, 151, 151, 0.2);
  border-radius: 5px;
  display: block;
}

/**
 * Import the elements
 */
/**
 * Bourbon interpolation to apply class to all form inputs
 * @note[1]: default light variant for all type text input elments.
 */
[type=color].error, [type=date].error, [type=datetime].error, [type=datetime-local].error, [type=email].error, [type=month].error, [type=number].error, [type=password].error, [type=search].error, [type=tel].error, [type=text].error, [type=time].error, [type=url].error, [type=week].error, input:not([type]).error, textarea.error,
input[type=file].error {
  color: #cf522d;
}
[type=color]:read-only, [type=date]:read-only, [type=datetime]:read-only, [type=datetime-local]:read-only, [type=email]:read-only, [type=month]:read-only, [type=number]:read-only, [type=password]:read-only, [type=search]:read-only, [type=tel]:read-only, [type=text]:read-only, [type=time]:read-only, [type=url]:read-only, [type=week]:read-only, input:not([type]):read-only, textarea:read-only,
input[type=file]:read-only {
  border-bottom: 0px;
}

/**
 * Form checkbox input: It is hidden because we visualize the (un)checked state through the nearby `label`
 * @see     ../_label.scss
 * @extends %input-hidden
 */
input[type=file] {
  padding-top: 14px;
  color: inherit;
  font-size: 0.875rem;
  line-height: 0.8;
}

/**
 * Form radio input: It is hidden because we visualize the state through the nearby `label`
 * @see     ../_label.scss
 * @extends %input-hidden
 */
/**
 * Select dropdown styling
 */
select {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: inherit;
  font-size: 0.9375rem;
  border: 0;
  outline: none;
  appearance: textfield;
}
select::-ms-expand {
  display: none;
}
select > option {
  color: #000000;
}

/**
 * Disabled select option
 */
option:disabled {
  color: #ccc;
  font-style: italic;
}

/**
 * Input button submit
 * @extends %button-action
 */
/*
input[type='search'],
input.search-input {

}
*/
/**
 * Form textarea
 */
textarea {
  width: 100%;
  padding-top: 0.5625rem;
  min-height: 68;
  line-height: 24px;
}
progress {
  appearance: none;
  height: 12px;
  border-radius: 5px;
}
progress::-webkit-progress-bar {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
}
progress::-webkit-progress-value {
  background-color: #000000;
  border-radius: 6px;
}

.input-field {
  position: relative;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0.375rem;
}
.input-field__input {
  width: 100%;
  height: 48px;
  z-index: 2;
}
.input-field__input::placeholder {
  opacity: 0;
}
.input-field__input.error + label {
  color: #cf522d;
}
.input-field__input:disabled, .input-field__input:disabled + label {
  opacity: 0.3;
}
.input-field__input.type--fill {
  background-color: #e6e6e6;
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}
.input-field__input.type--fill + label {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}
.input-field__input.type--small {
  min-width: 76px;
}
.input-field__input.has--value {
  background-color: #ffffff;
  margin-top: 1.125rem;
  padding-left: 0.5625rem;
}
.input-field__input.has--value + label, .input-field__input:focus + label, .input-field__input:not(:placeholder-shown) + label {
  transform: translateY(-215%);
  padding-left: 0;
  font-size: 0.75rem;
}
.input-field__label, .input-field__error {
  position: absolute;
  top: 50%;
  left: 0;
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out, padding 0.2s ease-in-out;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0.8;
}
.input-field__label {
  color: #000000;
  opacity: 1;
}
.input-field__label.type--text {
  margin-top: 1.5rem;
  position: relative;
  transform: translateY(0);
  display: block;
}
.input-field__error {
  font-size: 0.75rem;
  color: #cf522d;
  top: auto;
  bottom: 0;
  opacity: 1;
  transform: translateY(50%);
}
.input-field__textarea {
  margin-top: 0.75rem;
  padding-left: 0.75rem;
  background-color: #ffffff;
  margin-top: 0.75rem;
  margin-bottom: 0.375rem;
}

/**
 * The form labels
 * @Note[1]: Create radio button styling
 * @Note[2]: Create checkbox styling
 */
label {
  cursor: pointer;
}

label:has(input.ng-invalid) {
  color: #cf522d;
}

/**
 * All labels with a required class get a `*` to indicate they are required
 */
label.required::after {
  color: #abc71a;
  vertical-align: text-top;
  content: " *";
}
label.required::after.error {
  color: #cf522d;
}

/* [1] */
/**
 * switch layout select
 * @note for styling selectboxes see /modules/form/inputs/_select.scss
 * @note[1,2]: icon lower z-index for clickable selectbox
 * @file[1, 2]: extends/_inputs.scss
 */
.select {
  position: relative;
  padding: 0;
  min-width: 70px;
  font-weight: 700;
  fill: #757575;
}
.select select {
  position: relative;
  padding: 0.5625rem 44px 0.5625rem 0.5625rem;
  width: 100%;
  font-weight: 700;
}
.select .icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -5px;
  width: 12px;
  height: 12px;
  fill: inherit;
}

.search-form {
  position: relative;
  min-width: 200px;
}
.search-form__input {
  width: 100%;
  padding-right: 80px;
  height: 52px;
}
.type--small .search-form__input {
  padding-right: 44px;
  height: auto;
}
.search-form__button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.search-form__button:active {
  transform: translateY(-50%) scale(0.95);
}
.type--small .search-form__button {
  width: 44px;
  height: 44px;
  right: 0;
  min-width: 10px;
  background-color: transparent;
  border: 0;
  fill: #757575;
}
.type--small .search-form__button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 0;
  margin-right: 0;
}

@media all and (max-width: 47.8125em) {
  .search-form__button {
    width: 25px;
    min-width: 10px;
    text-indent: -200px;
  }
  .search-form .button .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
    margin-right: 0;
  }
}
.input-checkfield {
  color: #666666;
  margin-bottom: 0.75rem;
  /**
   * [1]
   * Label next to a radio button
   */
  /**
   * [2]
   * Label next to a *checked* radio button
   */
}
.input-checkfield input[type=radio] + label,
.input-checkfield input[type=checkbox] + label {
  position: relative;
  margin-right: 0.625rem;
  font-weight: normal;
  font-size: 0.9375rem;
  cursor: pointer;
  padding-left: 1.875rem;
}
.input-checkfield input[type=radio] + label + .form-error,
.input-checkfield input[type=checkbox] + label + .form-error {
  text-align: left;
}
.input-checkfield input[type=radio] + label .icon,
.input-checkfield input[type=checkbox] + label .icon {
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translate(-75%, -70%);
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}
.input-checkfield input[type=radio] + label:hover::before,
.input-checkfield input[type=checkbox] + label:hover::before {
  border-color: #666666;
}
.input-checkfield input[type=radio] + label::before,
.input-checkfield input[type=checkbox] + label::before {
  position: absolute;
  text-align: center;
  top: 0%;
  content: "";
  left: 0;
  width: 0.9375rem;
  height: 0.9375rem;
  background-color: #ffffff;
  border: 2px solid #666666;
  border-radius: 2px;
}
.input-checkfield input[type=radio]:checked + label .icon,
.input-checkfield input[type=checkbox]:checked + label .icon {
  opacity: 1;
}
.input-checkfield input[type=radio]:checked + label::before,
.input-checkfield input[type=checkbox]:checked + label::before {
  border-color: #3c859a;
  background-color: #3c859a;
  transition: border-color 150ms ease-in-out;
}
.input-checkfield input[type=radio].error + label,
.input-checkfield input[type=checkbox].error + label,
.input-checkfield input[type=checkbox].ng-invalid.ng-touched + label,
.input-checkfield input[type=checkbox].ng-invalid.ng-touched ~ span {
  color: #cf522d;
}
.input-checkfield input[type=radio].error + label::before,
.input-checkfield input[type=checkbox].error + label::before,
.input-checkfield input[type=checkbox].ng-invalid.ng-touched + label::before,
.input-checkfield input[type=checkbox].ng-invalid.ng-touched ~ span::before {
  border-color: #cf522d;
}
.input-checkfield input[type=radio] + label .icon {
  width: 12px;
  height: 12px;
  min-height: 4px;
  min-height: 4px;
  margin-left: 6px;
  margin-top: 3px;
  fill: #666666;
}
.input-checkfield input[type=radio] + label::before {
  border-radius: 50%;
}
.input-checkfield input[type=checkbox] + label .icon {
  width: 12px;
  height: 12px;
  min-height: 4px;
  min-height: 4px;
  margin-left: 6px;
  margin-top: 3px;
  fill: #ffffff;
}
form .ql-container.ql-snow,
form .ql-toolbar.ql-snow {
  border-color: #328da3;
}
form .ql-container.ql-snow button.ql-active,
form .ql-toolbar.ql-snow button.ql-active {
  background: #205a68;
  border-radius: 5px;
  color: white;
}
form .ql-container.ql-snow button.ql-active .ql-stroke,
form .ql-toolbar.ql-snow button.ql-active .ql-stroke {
  stroke: white;
}
form .ql-container.ql-snow button.ql-active .ql-fill,
form .ql-toolbar.ql-snow button.ql-active .ql-fill {
  fill: white;
}
form .ql-toolbar.ql-snow {
  border-radius: 5px 5px 0 0;
  background-color: #328da3;
}
form .ql-toolbar.ql-snow .ql-fill {
  fill: white;
}
form .ql-toolbar.ql-snow .ql-stroke {
  stroke: white;
}
form .ql-toolbar.ql-snow button:hover .ql-fill {
  fill: rgba(255, 255, 255, 0.8);
}
form .ql-toolbar.ql-snow button:hover .ql-stroke {
  stroke: rgba(255, 255, 255, 0.8);
}
form .ql-container.ql-snow {
  border-radius: 0 0 5px 5px;
}
form .ql-editor {
  min-height: 80px;
}

.header-stats {
  background-color: rgba(60, 133, 154, 0.1);
  padding: 0.5625rem 1.125rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-top: -1.5rem;
  margin-bottom: 3rem;
}
.header-stats .statistics {
  width: 100%;
  display: flex;
}
.header-stats .statistics app-stat-block {
  transform: scale(0.8);
  width: 200px;
  height: 220px;
  display: block;
  text-align: center;
  margin: 0 -12px;
}
.header-stats .statistics .panel {
  border: 0;
}
.header-stats .statistics__graph {
  width: 400px;
  height: 220px;
  padding: 1.125rem;
  margin-right: auto;
}

.notify {
  width: 0.875rem;
  height: 0.875rem;
  background: #cf522d;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #fff;
  transform: translateY(1px);
  box-shadow: 0 0 0 1px #cf522d, 0 0 5px #cf522d;
  animation: 750ms ease-in-out infinite alternate blink;
}

.comment-status {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.comment-status__inner {
  background-color: rgba(0, 0, 0, 0.05);
  display: inline-flex;
  padding: 0.3125rem 0.625rem;
  border-radius: 2.5rem;
  align-items: center;
  margin: 0.625rem auto 0.3125rem;
  color: #3c859a;
}
.comment-status__person {
  display: flex;
  align-items: center;
}
.comment-status__orb {
  margin-right: 0.3125rem;
}
.comment-status__time {
  font-family: "CiutadellaRounded-MdIt", monospace;
  font-size: 0.875rem;
  color: #3c859a;
  margin-bottom: 0.625rem;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 109;
  background-color: inherit;
}

.loading__indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  position: absolute;
  width: 200px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}
.loading__indicator.is--active {
  z-index: 40;
  display: flex;
}
.loading__indicator__item {
  display: inline-block;
  width: 32px;
  height: auto;
  transform-origin: center center;
  padding: 0 5px;
}
.loading__indicator__item svg {
  width: 100%;
  height: 100%;
  fill: #328da3;
}
.is--active .loading__indicator__item {
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.is--active .loading__indicator__item.is--bounce1 {
  animation-delay: -0.64s;
}
.is--active .loading__indicator__item.is--bounce2 {
  animation-delay: -0.56s;
}
.is--active .loading__indicator__item.is--bounce3 {
  animation-delay: -0.48s;
}
.is--active .loading__indicator__item.is--bounce4 {
  animation-delay: -0.32s;
}
.is--active .loading__indicator__item.is--bounce5 {
  animation-delay: -0.16s;
}
.is--active .loading__indicator__item.is--bounce6 {
  animation-delay: 0s;
}

@keyframes sk-bouncedelay {
  /* stylelint-disable selector-list-comma-newline-after */
  0%, 80%, 100% {
    /* stylelint-enable selector-list-comma-newline-after */
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.login .login__form {
  position: relative;
  top: 60px;
  border-radius: 15px;
  max-width: 360px;
  margin: 0 auto;
  padding: 1.125rem;
  background-color: white;
}
.login .login__form__welcome-text {
  position: absolute;
  top: -24px;
  left: 0;
  color: white;
  transform: translateY(-75%);
  opacity: 0;
  animation: welcome-text--fade-in 0.3s cubic-bezier(0.35, 0, 0.25, 1) 1s forwards;
}
.login .login__form__title {
  line-height: 0;
  margin-top: 0.75em !important;
}
.login .login__form__field {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.login .login__form__field .form-control .mat-form-field {
  width: 100%;
}
.login .login__form__field .form-control .mat-form-field input:-webkit-autofill {
  box-shadow: 0 0 0 30px #fff inset;
}
.login .login__form__field button {
  align-self: flex-end;
}
.login .login__form__field button .mat-icon {
  margin-left: 6px;
}

@keyframes welcome-text--fade-in {
  from {
    transform: translateY(-75%);
    opacity: 0;
  }
  to {
    transform: translateY(-100%);
    opacity: 1;
  }
}
[hidden] {
  display: none !important;
}

html,
body {
  width: 100%;
}

/**
* Body tag styling
*/
body {
  font-family: "Barlow";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  line-height: 1.5;
  overflow-x: hidden;
  font-size: 1rem;
}
@media all and (min-width: 47.875em) {
  body {
    padding-top: 5.9375rem;
  }
}

/**
* A-tag styling
*/
a {
  color: inherit;
  text-decoration: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: inherit;
}

p,
.p {
  margin: 0 0 1.5em;
  line-height: 1.61;
}

figure {
  margin: 0;
}

img {
  display: inline-block;
}

/**
* Reset strong for bold styling kuch
*/
strong,
b {
  font-weight: 700;
}

hr {
  margin: 3rem 0;
  border-color: #757575;
}

/**
* Window.breakpoint
* [1] For mobile
* [2] For tablet
* [3] For desktop
* [4] For ultra desktop
*/
@media all and (max-width: 47.8125em) {
  body::after {
    content: "breakpoint-mobile";
    display: none;
  }
}
@media all and (min-width: 47.875em) and (max-width: 56.1875em) {
  body::after {
    content: "breakpoint-tablet";
    display: none;
  }
}
@media all and (min-width: 56.25em) and (max-width: 81.1875em) {
  body::after {
    content: "breakpoint-desktop";
    display: none;
  }
}
@media all and (min-width: 81.25em) {
  body::after {
    content: "breakpoint-large-desktop";
    display: none;
  }
}

[hidden] {
  display: none;
}

.fw-normal {
  font-weight: normal !important;
}

@keyframes slide-in {
  0% {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes scale-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.08, 1.08, 1.08);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bg--white {
  background-color: #ffffff;
}

.bg--black {
  background-color: #000000;
}

.bg--todo, .mat-radio-button.type--todo .mat-radio-outer-circle, .mat-radio-button.type--todo .mat-radio-inner-circle {
  background-color: #3c859a;
}

.bg--priority, .mat-radio-button.type--priority .mat-radio-outer-circle, .mat-radio-button.type--priority .mat-radio-inner-circle {
  background-color: #f53d6f;
}

.bg--doing, .mat-radio-button.type--doing .mat-radio-outer-circle, .mat-radio-button.type--doing .mat-radio-inner-circle {
  background-color: #afdce3;
}

.bg--verify, .mat-radio-button.type--verify .mat-radio-outer-circle, .mat-radio-button.type--verify .mat-radio-inner-circle {
  background-color: #fce648;
}

.bg--done, .mat-radio-button.type--done .mat-radio-outer-circle, .mat-radio-button.type--done .mat-radio-inner-circle {
  background-color: #abc71a;
}

.bg--new, .mat-radio-button.type--new .mat-radio-outer-circle, .mat-radio-button.type--new .mat-radio-inner-circle {
  background-color: #ffffff;
}

.block {
  margin-bottom: 3rem;
}
.block__title {
  margin-bottom: 2ex;
}
.columns {
  display: flex;
}

.column-1-2 {
  width: 50%;
  flex: 1;
}

@media all and (max-width: 47.8125em) {
  .column-1-2 {
    width: 100%;
  }
}
.color--white {
  color: #ffffff;
  fill: #ffffff;
}

.color--black, .input-field__input, .input-field__input.has--value {
  color: #000000;
  fill: #000000;
}

.container {
  margin: 0 auto;
  position: relative;
  z-index: 5;
  width: 100%;
}
.container.type--xs {
  max-width: 500px;
}
.container.type--s {
  max-width: 620px;
}
.container.type--m {
  max-width: 830px;
}
.container.type--l {
  max-width: 1100px;
}
.container.type--xl {
  max-width: 1300px;
}

.page-section {
  margin-top: 5.75rem;
}

@media all and (max-width: 47.8125em) {
  .container {
    padding: 0 0.9375rem;
  }
  .page-section {
    margin-top: 6.875rem;
  }
}
@media all and (min-width: 47.875em) {
  .container {
    padding: 0 1.5rem;
  }
}
.grid-1-3 {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: flex-start;
  width: auto;
  /*
   * @note -flex-group... is fix for margin-bottom block
   */
  overflow: hidden;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.grid-1-3 .grid__item {
  display: flex;
}
@media all and (min-width: 81.25em) {
  .grid-1-3 .grid__item {
    margin: 0 0.75rem 1.5rem;
    flex-basis: calc(33.3333333333% - 1.5rem);
  }
}
@media all and (min-width: 56.25em) and (max-width: 81.1875em) {
  .grid-1-3 .grid__item {
    margin: 0 0.75rem 1.5rem;
    flex-basis: calc(33.3333333333% - 1.5rem);
  }
}
@media all and (min-width: 47.875em) and (max-width: 56.1875em) and (orientation: landscape) {
  .grid-1-3 .grid__item {
    margin: 0 0.75rem 1.5rem;
    flex-basis: calc(25% - 1.5rem);
  }
}
@media all and (min-width: 47.875em) and (max-width: 56.1875em) and (orientation: portrait) {
  .grid-1-3 .grid__item {
    margin: 0 0.75rem 1.5rem;
    flex-basis: calc((33.3333333333%) - 1.5rem);
  }
}
@media all and (max-width: 47.8125em) {
  .grid-1-3 .grid__item {
    margin: 0 0.375rem 0.75rem;
    flex-basis: calc((50%) - 0.75rem);
  }
}

.page-section .mat-headline {
  color: #3c859a;
  text-transform: capitalize;
}
.page-section__header form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-section__search {
  width: 100%;
  margin-top: 1.5rem;
  color: #3c859a;
}
.page-section__search .mat-icon {
  vertical-align: top;
  font-size: 28px;
  margin-top: -3px;
  height: 32px;
  width: 32px;
}
.page-section__filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.page-section__filter__status-reset {
  position: relative;
  color: white;
  border-radius: 12px;
  background-color: #cf522d;
  width: auto;
  padding: 3px 10px 5px;
  margin-left: 20px;
}
.page-section__filter__status-reset::before {
  content: "";
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: -16px;
  border-left: 1px solid black;
  color: grey;
  height: 12px;
  opacity: 0.2;
}
.page-section__results {
  margin-top: 1.5rem;
}
.page-section__results__list-header {
  display: flex;
  justify-content: space-between;
  color: #328da3;
  margin: 0.75rem 0 0.15rem;
}
.page-section__results__list-header h1 {
  font-weight: 700;
}
.page-section__list-title {
  margin-bottom: 0;
  text-transform: uppercase;
  color: #328da3;
  padding-top: 1.5rem;
}
.page-section__list__item {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  margin: 0.3rem 0;
}
.page-section__list__item.is--disabled {
  background-color: #f8e5e0;
  color: #cf522d;
  border: 1px solid #efc8be;
}
.page-section__list__item.is--disabled:hover {
  background: rgba(239, 200, 190, 0.7) !important;
}
.page-section__list__item:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.page-section__list__item-label {
  font-family: inherit;
  font-weight: 700 !important;
  color: #757575;
  text-transform: none !important;
  line-height: normal !important;
}
.is--disabled .page-section__list__item-label {
  color: #cf522d;
}
.page-section__list__item-icon {
  color: #328da3;
}
.is--disabled .page-section__list__item-icon {
  color: #cf522d;
}

.text-center {
  text-align: center;
}

.caption {
  color: #757575;
  font-size: 0.8em;
}

.subtitle {
  font-weight: 100;
}

.spacer {
  flex-grow: 1;
}

/**
 * Element is hidden
 */
.is--hidden {
  display: none !important;
}

.is--invisible {
  opacity: 0;
}

.is--visible {
  opacity: 1;
}

.is--fixed {
  position: fixed;
}

.is--clickable {
  cursor: pointer;
}

.disable-scrolling {
  overflow: hidden;
  width: 100%;
  height: auto;
}
@media all and (max-width: 47.8125em) {
  .disable-scrolling {
    height: 100vh;
  }
}

/**
* Helpers
**/
.pre-line {
  white-space: pre-line;
}

.no--underline, .no--underline:hover, .no--underline:focus, .no--underline:active {
  text-decoration: none;
}

.p1 {
  padding: 1.125rem;
}

.shadowZ2 {
  box-shadow: 2px 4px 11px 0 rgba(0, 0, 0, 0.22);
}

quill-editor .ql-container {
  height: auto;
}
quill-editor .ql-container em {
  font-style: italic;
}

.page__lanes {
  margin-left: -0.5625rem;
  margin-right: -0.5625rem;
  min-width: 1200px;
  display: flex;
  align-content: stretch;
  min-height: 100vh;
}
.page__lanes-lane {
  padding: 0 0.5625rem;
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
}
.page__title {
  position: relative;
  padding: 1.125rem 1.125rem 0.5625rem 40px;
  border: 1px solid #afdce3;
  border-radius: 9px 9px 0 0;
  border-bottom: 0;
  margin-bottom: 0;
  color: #328da3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page__title app-status-orb {
  position: absolute;
  transform: scale(0.5);
  display: inline-block;
  left: 12px;
  top: 17px;
}
.page__title em {
  font-family: "CiutadellaRounded-MdIt", monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8em;
}
.page__list {
  border: 1px solid #afdce3;
  border-top: 0;
  height: 100%;
  border-radius: 0 0 9px 9px;
  padding: 0.5625rem 1.125rem 1.125rem;
}
.page__list-item {
  margin-bottom: 0.75rem;
  position: relative;
  overflow: hidden;
  border-radius: 4.5px;
}
.page__list-item:active {
  box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.1s ease-in-out;
}
.page__list-item:hover ::ng-deep .backlog-card {
  border: 1px solid #afdce3;
  transition: border 0.1s ease-in-out;
}
.page__list-item:last-child {
  margin-bottom: 0;
}
.page__list-placeholder {
  position: relative;
  background-color: rgba(60, 133, 154, 0.1);
  border-radius: 4.5px;
  min-height: 50px;
  margin-bottom: 0.75rem;
}
.page__list-placeholder .mat-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #328da3;
}

/* Append pseudo-classes to a selector(s).
*
* @argument {list | string} $inputs
*   A selector, or list of selectors, to apply the pseudo-class to.
*
* @argument {pseudo-class} $pseudo [null]
*   The pseudo-class to be appended.
*
* @return {list}
*
* @access private
*/
/**
 * Function to pick an color off a sass map
 *
 * @see ../settings/_colors.scss
 *
 * @param {string} $color [grey]
 * @param {string} $variant [800]
 * @param {string} $alpha [.68]
 *
 * @example .test { background-color: color(grey); } - get the base color of the grey object
 * @example .test { background-color: color(grey, 700); } - get the color variant of the grey object
 * @example .test { background-color: color(grey, 700, .2); } - get the color variant of the grey object in
 *                                                              a rgba with alpha
 * @example .test { background-color: color(grey, $alpha: .68); } - get the color variant of the grey object in
 *                                                                  a rgba with the base color and a alpha
 */
/**
 * Color key base variable
 */
/**
 * Function to create a rgba color off a sass map
 *
 * @see ../settings/_colors.scss
 *
 * @param {string} $flavour [dark]
 * @param {string} $tint [secondary]
 * @param {string} $base-key [base]
 *
 * @example .test { color: font-color(dark, primary); }
 */
/**
 * Remove the unit of a length
 * @param {Number} $number - Number to remove unit from
 * @return {Number} - Unitless number
 */
/**
 * Convert pixels to ems
 * eg. for a relational value of 12px write em(12) when the parent is 16px
 * if the parent is another value say 24px write em(12, 24)
 */
/**
 * Convert pixels to rems
 * eg. for a relational value of 12px write rem(12)
 * Assumes $em-base is the font-size of <html>
 */
/**
 * Size functions off a sass map
 *
 * @see ../settings/_sizes.scss
 */
/*
 * Default size base variable
 */
/**
 * Calc-math base function
 *
 * @param {string} $type
 * @param {string} $size [0]
 *
 * @example calc-math(margin, $size);
 */
/**
 * Margin function
 *
 * @param {string} $size [0]
 *
 * @example cm(1);
 * @return {number} number or rem or em value
 */
/**
 * Padding function
 *
 * @param {string} $size [0]
 *
 * @example cp(1);
 * @return {number} number or rem or em value
 */
@font-face {
  font-family: "CiutadellaRounded-BdIt";
  src: url("/assets/fonts/381841_0_0.eot");
  src: url("/assets/fonts/381841_0_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/381841_0_0.woff2") format("woff2"), url("/assets/fonts/381841_0_0.woff") format("woff"), url("/assets/fonts/381841_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "CiutadellaRounded-MdIt";
  src: url("/assets/fonts/381841_1_0.eot");
  src: url("/assets/fonts/381841_1_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/381841_1_0.woff2") format("woff2"), url("/assets/fonts/381841_1_0.woff") format("woff"), url("/assets/fonts/381841_1_0.ttf") format("truetype");
}
/**
 * Mixins
 */
/**
 * Before
 * @param $width - the width of the psuedo element (default is 100%)
 * @param $height - the height of the psuedo element (default is 100%)
 * @param $content - content property for the before element (default is empty)
 * @param $icon - default there is no icon (true, false)
 * @param $display - configure the display property (default is block) ('block', 'inline-block' || '' || 'poep')
 */
/**
 * Simple color mixin to declare background and color values
 *
 * @require {function} color
 * @require {map} $colors
 *
 * @param {string} $bg-key [gray] - select color key from $colors map
 * @param {string} $bg-variant [500] - select color variant from $colors map
 * @param {string} $color-key [white] - select color key from $colors map
 * @param {string} $color-variant [500] - select color variant from $colors map
 */
/**
 * Base clearfix mixin
 */
/**
 * Clearfix on pseudo element mixin
 */
/**
 * Flexbox grid
 * @param {number} $gutter - unitless size between the columns
 * @param {string} $selector - selector off the children elements (as string, '.CLASSNAME', [data attr]),
 * @param {number} $column-align - property value of justify content,
 * @param {number} $columns-desktop - How many columns needed on desktop,
 * @param {number} $columns-tablet - How many columns needed on tablet,
 * @param {number} $columns-mobile - How many columns needed on mobile,
 * @param {number} $columns-mobile-landscape - How many columns needed on mobile but with an orientation of landscape
 */
/**
 * Default variable settings
 */
/**
 * Flex-group mixin
 * [1] if column align value is space-around there is not left of right margin needed
 * [2] @content directive included
 * [3] Interpolate children selector for customization
 */
/**
 * Grid Mixins
 * home of mixins grid-container and grid-column
 * exception for the grid-column-width function to keep it up together
 */
/**
 * Default variable settings
 */
/**
 * Grid column width calculation
 * @param {number} $columns [0] - amount of columns needed for the result
 * @param {number} $amount [$grid-columns-amount] - amount of columns drawn in design
 * @return {number} calculate width
 */
/**
 * Grid container
 * @param {number} $gutter [$grid-columns-gutter] - unitless size between columns in design
 * @param {number} $container-width [$site-width] calculate percentage margin with container width
 *
 * @example
 *    .grid-container {
 *        @include grid-container;
 *    }
 */
/**
 * Grid column
 * @param {number} $columns [0] - amount of columns needed for the result
 * @param {number} $gutter-left [$grid-columns-gutter] - workaround to affect the padding-left
 * @param {number} $gutter-right [$grid-columns-gutter] - workaround to affect the padding-right
 * @param {number} $amount [$grid-columns-amount] - amount of columns drawn in design
 * @param {boolean} $workaround [false] - set !important flag
 * @param {string} $container-width calculate margin and widht adhv parent container width
 * @require {function} grid-column-width - calculate width of the column
 *
 * @example
 *    .grid-column-1 {
 *        @include grid-column(3);
 *    }
 */
/**
 * Media query extended (or)
 * @param {list} $mq-values - space separated list of media queries
 * @require {list} $mappy-query - default sass map from mappy-breakpoints
 * @require {function} implode - function used to glue strings together, from mappy-breakpoints
 */
/**
 * An inline panel which collapses on mobile devices
 */
/**
 * The two states of the inline (collapsable) panel title
 */
/**
 * Separator above the inline content
 */
/**
 * Before
 * @param $width - the width of the psuedo element (default is 100%)
 * @param $height - the height of the psuedo element (default is 100%)
 * @param $content - content property for the before element (default is empty)
 * @param $icon - default there is no icon (true, false)
 * @param $display - configure the display property (default is block) ('block', 'inline-block' || '' || 'poep')
 */
/*
@mixin text-overflow($lines: 3, $max-height: em(75)) {
    overflow: hidden;
    display: -webkit-box;
    max-height: $max-height;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}
*/
/**
 * Mixin for vertical centering elements in the middle
 *
 * @note no params needed
 * @example .test { @include vertical-align; }
 */
/**
 * Placeholder for a nice aspect ratio wrapper
 *
 * What is aspect ratio?
 * The aspect ratio of an element describes the proportional relationship between its width and its height.
 * Two common video aspect ratios are 4:3 (the universal video format of the 20th century), and 16:9 (universal for
 * HD television and European digital television)
 */
/**
 * Placeholder for a image or children element in an aspect ratio wrapper
 * @note pulled from parent wrapper to prevent double styling or unused styling
 */
.app__toolbar__user__avatar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.no-objectfit .app__toolbar__user__avatar img, .app__toolbar__user__avatar .no-objectfit img {
  height: auto;
}
:root .app__toolbar__user__avatar img, _:-ms-lang(x) .app__toolbar__user__avatar img, .app__toolbar__user__avatar _:-ms-lang(x) img {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.app__wrapper {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 3;
}
.app__wrapper.anim--in {
  opacity: 1;
}
.app .mat-drawer-content {
  overflow: inherit;
}

.app__toolbar {
  padding-left: 95px;
  position: fixed;
  z-index: 99;
  top: 0;
  transition: height 0.2s ease-in-out;
}
.app__toolbar.set--fixed {
  background-color: white;
  height: 60px;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
}
.app__toolbar__user__avatar {
  position: absolute;
  top: 50%;
  left: 1.125rem;
  transform: translateY(-45%);
  width: 60px;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.app__toolbar__user__avatar::before {
  padding-top: 100%;
}
.set--fixed .app__toolbar__user__avatar {
  transform: scale(0.7) translateY(-45%);
  transform-origin: top left;
  left: 1.6875rem;
}
.app__toolbar__logo {
  position: absolute;
  top: 50%;
  width: 32px;
  height: 48px;
  margin-bottom: 0;
  transform: translateY(-58%);
  left: 110px;
  transition: all 0.2s ease-in-out;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-image: url("/assets/gfx/logo-afterburner.svg");
}
.app__toolbar__logo .logo {
  width: 100%;
  height: 100%;
  font-size: 0px;
  display: block;
}
.set--fixed .app__toolbar__logo {
  width: 26px;
  height: 38px;
  left: 98px;
}

.app__sidebar {
  position: fixed;
  z-index: 99;
  top: 96px;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  will-change: scroll-position;
  transition: top 0.2s ease-in-out;
}
@media all and (min-width: 47.875em) {
  .app__sidebar.set--fixed {
    top: 60px;
  }
}

.app-title {
  font-family: "CiutadellaRounded-MdIt", monospace;
  font-size: 1.5625rem;
  display: flex;
  padding-left: 62px;
  align-items: center;
  font-weight: 300;
  color: #3c859a;
  transition: all 0.2s ease-in-out;
}
.app-title .mat-icon {
  margin-right: 5px;
}
.app-title strong {
  margin-left: 0.5ex;
}
.set--fixed .app-title {
  padding-left: 42px;
}

.mat-toolbar {
  background: #fff;
  border-bottom: solid 1px #eaeaea;
  height: 96px;
}
@media all and (max-width: 47.8125em) {
  .mat-toolbar {
    height: 4rem;
    position: fixed;
    z-index: 100;
  }
}

.mat-drawer-content {
  min-height: calc(100vh - 96px);
}

@media all and (max-width: 47.8125em) {
  .mat-sidenav {
    position: fixed;
    top: unset;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 2px 30px rgba(117, 117, 117, 0.1);
  }
}

@media all and (max-width: 47.8125em) {
  .mat-drawer-content {
    margin-left: 0 !important;
    padding-bottom: 4rem;
  }
}