.text-center {
  text-align: center;
}

.caption {
  color: color(grey);
  font-size: 0.8em;
}

.subtitle {
  font-weight: 100;
}

.spacer {
  flex-grow: 1;
}

/**
 * Element is hidden
 */
.is--hidden {
  display: none !important;
}

.is--invisible {
  opacity: 0;
}

.is--visible {
  opacity: 1;
}

.is--fixed {
  position: fixed;
}

.is--clickable {
  cursor: pointer;
}

.disable-scrolling {
  overflow: hidden;
  // position: absolute;
  // position: relative;
  width: 100%;
  height: auto;

  @include mappy-query(mobile) {
    height: 100vh;
  }
}

/**
* Helpers
**/
.pre-line {
  white-space: pre-line;
}

.no--underline {
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.p1 {
  padding: cp(1);
}

.shadowZ2 {
  box-shadow: 2px 4px 11px 0 rgba(0, 0, 0, 0.22);
}

.rte {
  @extend %rte;
}
quill-editor .ql-container {
  height: auto;

  em {
    font-style: italic;
  }
}

.block-link {
  @extend %block-link;
}
