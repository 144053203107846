@use '@angular/material' as mat;
.page-section {
  .mat-headline {
    color: mat.get-color-from-palette($md-fstrprimary, 500);
    text-transform: capitalize;
  }

  &__header form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__search {
    width: 100%;
    margin-top: cm(1);
    color: mat.get-color-from-palette($md-fstrprimary, 500);

    .mat-icon {
      vertical-align: top;
      font-size: 28px;
      margin-top: -3px;
      height: 32px;
      width: 32px;
    }
  }

  &__filter {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__status-reset {
      position: relative;
      color: white;
      border-radius: 12px;
      background-color: mat.get-color-from-palette($md-fstrsecondary, 500);
      width: auto;
      padding: 3px 10px 5px;
      margin-left: 20px;

      &::before {
        content: '';
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: -16px;
        border-left: 1px solid black;
        color: grey;
        height: 12px;
        opacity: 0.2;
      }
    }
  }

  &__results {
    margin-top: cm(1);
  }

  &__results__list-header {
    display: flex;
    justify-content: space-between;
    color: color(blue);
    margin: cm(0.5) 0 cm(0.1);
    h1 {
      font-weight: 700;
    }
  }

  &__list-title {
    margin-bottom: 0;
    // font-weight: 700 !important;
    // font-size: 0.9em;
    text-transform: uppercase;
    color: color(blue, 500);
    padding-top: cm(1);
  }

  &__list__item {
    background-color: color(white, 500);
    border-radius: 5px;
    border: 1px solid color(grey, 300);
    margin: cm(0.2) 0;

    &.is--disabled {
      background-color: color(red, 200);
      color: color(red, 500);
      border: 1px solid color(red, 300);

      &:hover {
        background: color(red, 300, $alpha: 0.7) !important;
        // border: 1px solid color(red, 300);
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.03) !important;

      & .is--disabled {
      }
    }

    &-label {
      font-family: inherit;
      font-weight: 700 !important;
      color: color(grey);
      text-transform: none !important;
      line-height: normal !important;

      .is--disabled & {
        color: color(red, 500);
      }
    }

    &-icon {
      color: color(blue, 500);

      .is--disabled & {
        color: color(red, 500);
      }
    }
  }
}
