@import '../static/scss/import-component';
@import '../static/scss/extends/extend-aspect-ratio';

.app {
  &__wrapper {
    // position: relative;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 3;

    &.anim--in {
      opacity: 1;
    }
  }

  .mat-drawer-content {
    overflow: inherit;
  }
}

.app__toolbar {
  padding-left: 95px;
  position: fixed;
  z-index: z-index(nav);
  top: 0;
  transition: height 0.2s ease-in-out;

  &.set--fixed {
    background-color: white;
    height: 60px;
    box-shadow: 0 0 15px 2px color(black, $alpha: 0.1);
  }

  &__user__avatar {
    @include abs(50%, null, null, cp(1));
    transform: translateY(-45%);
    width: 60px;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;

    &::before {
      padding-top: 100%;
    }

    img {
      @extend %aspect-ratio-element;
    }

    .set--fixed & {
      transform: scale(0.7) translateY(-45%);
      transform-origin: top left;
      left: cp(1.5);
    }
  }

  &__logo {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 48px;
    margin-bottom: 0;
    transform: translateY(-58%);
    left: 110px;
    transition: all 0.2s ease-in-out;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url('/assets/gfx/logo-afterburner.svg');

    .logo {
      @include size(100%, 100%);
      font-size: 0px;
      display: block;
    }

    .set--fixed & {
      width: 26px;
      height: 38px;
      left: 98px;
    }
  }
}

.app__sidebar {
  position: fixed;
  z-index: z-index(nav);
  top: 96px;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0); // enable hardware acceleration
  will-change: scroll-position;
  transition: top 0.2s ease-in-out;

  @include mappy-bp(small) {
    &.set--fixed {
      top: 60px;
    }
  }
}

.app-title {
  font-family: $header-font-medium;
  font-size: rem(25);
  display: flex;
  padding-left: 62px;
  align-items: center;
  font-weight: 300;
  color: color(aqua);
  transition: all 0.2s ease-in-out;

  .mat-icon {
    margin-right: 5px;
  }

  strong {
    margin-left: 0.5ex;
  }

  .set--fixed & {
    padding-left: 42px;
  }
}

.mat-toolbar {
  background: #fff;
  border-bottom: solid 1px #eaeaea;
  height: 96px;

  @include mappy-query(mobile) {
    height: rem(64px);
    position: fixed;
    z-index: 100;
  }
}

.mat-drawer-content {
  min-height: calc(100vh - 96px);
}

.mat-sidenav {
  @include mappy-query(mobile) {
    position: fixed;
    top: unset;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 2px 30px rgba(117, 117, 117, 0.1);
  }
}

.mat-drawer-content {
  @include mappy-query(mobile) {
    margin-left: 0 !important;
    padding-bottom: rem(64px);
  }
}
