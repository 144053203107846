.block {
  margin-bottom: cm(2);

  &__title {
    margin-bottom: 2ex;
  }

  &__text {
    @extend %rte;
  }
}
