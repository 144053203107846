/**
 * Mixin for vertical centering elements in the middle
 *
 * @note no params needed
 * @example .test { @include vertical-align; }
 */
@mixin vertical-align {
    top: 50%;
    transform: translateY(-50%);
}
