.input-field {

  position: relative;
  width: 100%;
  padding: rem(10) 0;
  margin-bottom: cm(.25);

  &__input {
    @extend .color--black;

    width: 100%;
    height: 48px;
    z-index: 2;


    &::placeholder {
      opacity: 0;
    }

    &.error + label {
      color: color(red);
    }

    &:disabled,
    &:disabled + label {
      opacity: .3;
    }

    &.type--fill {
      background-color: color(grey, 300);
      padding-left: cp(.5);
      padding-right: cp(.5);

      + label {
        padding-left: cp(.5);
        padding-right: cp(.5);


      }
    }

    &.type--small {
      min-width: 76px;
    }

    &.has--value {
      @extend .color--black;

      background-color: color(white);
      margin-top: cp(1);
      padding-left: cp(.5);
    }

    &.has--value + label,
    &:focus + label,
    &:not(:placeholder-shown) + label, {
      transform: translateY(-215%);
      padding-left: 0;
      font-size: rem(12);
    }
  }

  &__label,
  &__error {
    @include abs(50%, null, null, 0);
    transition: transform .2s ease-in-out, font-size .2s ease-in-out, padding .2s ease-in-out;
    transform: translateY(-50%);
    z-index: 1;
    opacity: .8;
  }

  &__label {
    color: color(black);
    opacity: 1;

    &.type--text {
      margin-top: cm(1);
      position: relative;
      transform: translateY(0);
      display: block;
    }
  }

  &__error {
    font-size: rem(12);
    color: color(red);
    top: auto;
    bottom: 0;
    opacity: 1;
    transform: translateY(50%);
  }

  &__textarea {
    margin-top: cm(.5);
    padding-left: cm(.5);
    background-color: color(white);
    margin-top: cm(.5);
    margin-bottom: cm(.25);
  }
}
