.mat-color-picker {
  position: relative;

  &__button {
    @include abs(50%, 0,null,null);
    transform: translateY(-50%);
    color: color(blue);
  }

  &__example {
    @include abs( 50%, null, null, 0);
    @include size(10px, 10px);
    border-radius: 100%;
    display: inline-block;
    transform: translateY(-50%);
  }

  .colorPicker {
    padding-left: 20px;
  }
}

.color-picker {
  box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.20);
}
