/**
 * Convert pixels to rems
 * eg. for a relational value of 12px write rem(12)
 * Assumes $em-base is the font-size of <html>
 */

$em-base: 16px !default;

@function rem($pxval, $base: $em-base) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }
    @if not unitless($base) {
        $base: strip-units($base);
    }

    @return ($pxval / $base) * 1rem;
}
