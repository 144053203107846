/**
 * Bourbon interpolation to apply class to all form inputs
 * @note[1]: default light variant for all type text input elments.
 */
#{$all-text-inputs},
input[type='file'] {
  @extend %input-base;
  // @extend %input-border-bottom;

  &.error {
    @extend %input-error-color;

    color: color(red);
  }

  &:read-only {
     border-bottom: 0px;
  }
}

