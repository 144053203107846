/**
 * Before
 * @param $width - the width of the psuedo element (default is 100%)
 * @param $height - the height of the psuedo element (default is 100%)
 * @param $content - content property for the before element (default is empty)
 * @param $icon - default there is no icon (true, false)
 * @param $display - configure the display property (default is block) ('block', 'inline-block' || '' || 'poep')
 */

@mixin before(
    $display: 'block',
    $width: 100%,
    $height: 100%,
    $content: '',
    $icon: false
) {
    width: $width;
    height: $height;
    content: $content;
    @if $display == 'block' {
        display: block;
    } @else {
        display: inline-block;
    }
}
