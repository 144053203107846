/**
 * Media query extended (or)
 * @param {list} $mq-values - space separated list of media queries
 * @require {list} $mappy-query - default sass map from mappy-breakpoints
 * @require {function} implode - function used to glue strings together, from mappy-breakpoints
 */
 @mixin mq($mq-values) {
  /**
   * Create new list for storing media-strings
   */
  $mq-string: ();

  /**
   * Loop through argument values
   */
  @each $mq in $mq-values {
      /**
       * Check if given loop item is in $mappy-queries sass map
       */
      @if map-has-key($mappy-queries, $mq) {
          /**
           * Store map value
           */
          $bp-map: map-get($mappy-queries, $mq);
          /**
           * Select media-string part
           */
          $bp: map-get($bp-map, media-string);

          /**
           * If $mq-string is empty...
           */
          @if $mq-string == (()) {
              /**
               * Then append first media-string
               */
              $mq-string: append($mq-string, unquote('all and ' + $bp));
          } @else {
              /**
               * Else append media-string with an extra comma prefixed
               */
              $mq-string: append($mq-string, unquote(', all and ' + $bp));
          }
      } @else {
          /**
           * Give media query is not in the $mappy-queries sass map
           */
          @warn '`#{$mq}` is not a valid key in `$mappy-queries`.';
      }
  }

  /**
   * Check if implode function exists...
   */
  @if function-exists(implode) {
      /**
       * Then glue the $mq-string list parts together
       */
      $mq-string: implode($mq-string);
  } @else {
      /**
       * Else warning in console, and a hint that mappy-breakpoint is not available
       */
      @warn 'function `implode` is not installed, install `$mappy-breakpoints`.';
  }

  /**
   * Write media query in the css
   */
  @media #{$mq-string} {
      @content;
  }
}
