.login .login__form {
    position: relative;
    top: 60px;
    border-radius: 15px;
    max-width: 360px;
    margin: 0 auto;
    padding: cp(1);
    background-color: white;
    &__welcome-text {
        @include abs(-24px, null, null, 0);
        color: white;
        transform: translateY(-75%);
        opacity: 0;
        animation: welcome-text--fade-in $transition-slow 1s forwards; // keyframe @ bottom of this file
    }
    &__title {
        line-height: 0;
        margin-top: .75em !important;
    }
    &__field {
        margin-top: cm(1);
        display: flex;
        flex-direction: column;
        .form-control .mat-form-field {
            width: 100%;
            // autofill background
            input:-webkit-autofill {
                box-shadow: 0 0 0 30px #fff inset;
            }
        }
        button {
            align-self: flex-end;
            .mat-button-wrapper {
                @extend %flex-center;
            }
            .mat-icon {
                margin-left: 6px
            }
        }
    }
}

@keyframes welcome-text--fade-in {
    from {
        transform: translateY(-75%);
        opacity: 0;
    }
    to {
        transform: translateY(-100%);
        opacity: 1;
    }
}

[hidden] {
    display: none !important;
}
