.columns {
  display: flex;
}

.column-1-2 {
  width: 50%;
  flex: 1;
}

@include mappy-query(mobile) {
  .column-1-2 {
    width: 100%;
  }
}
