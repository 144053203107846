.mat-snack-bar-container {
  background-color: color(aqua);

  // &.is--success {
  //   background-color: color(green);
  //   color: color(black);
  // }

  &.is--error {
    background-color: color(red);
  }
}
