/**
 * Grid Mixins
 * home of mixins grid-container and grid-column
 * exception for the grid-column-width function to keep it up together
 */

/**
 * Default variable settings
 */
$grid-columns-amount: 12 !default;
$grid-columns-gutter: 10 !default;
$grid-container-width: $site-width !default;

/**
 * Grid column width calculation
 * @param {number} $columns [0] - amount of columns needed for the result
 * @param {number} $amount [$grid-columns-amount] - amount of columns drawn in design
 * @return {number} calculate width
 */
@function grid-column-width(
    $columns: 0,
    $amount: $grid-columns-amount
) {
    /**
     * Magic part where the width is calculated
     */
    $column-width: percentage($columns / $amount);
    /**
     * Show in the terminal an warning if $columns is not set or 0
     */
    @if $columns == 0 {
        @warn '[grid-column]: $columns argument isn\'t set';
    }

    @return $column-width;
}

/**
 * Grid container
 * @param {number} $gutter [$grid-columns-gutter] - unitless size between columns in design
 * @param {number} $container-width [$site-width] calculate percentage margin with container width
 *
 * @example
 *    .grid-container {
 *        @include grid-container;
 *    }
 */
@mixin grid-container(
    $gutter: $grid-columns-gutter,
    $container-width: $site-width
) {
    position: relative;
    margin-right: (percentage($gutter / strip-units($container-width))) * -1;
    margin-left: (percentage($gutter / strip-units($container-width))) * -1;
}

/**
 * Grid column
 * @param {number} $columns [0] - amount of columns needed for the result
 * @param {number} $gutter-left [$grid-columns-gutter] - workaround to affect the padding-left
 * @param {number} $gutter-right [$grid-columns-gutter] - workaround to affect the padding-right
 * @param {number} $amount [$grid-columns-amount] - amount of columns drawn in design
 * @param {boolean} $workaround [false] - set !important flag
 * @param {string} $container-width calculate margin and widht adhv parent container width
 * @require {function} grid-column-width - calculate width of the column
 *
 * @example
 *    .grid-column-1 {
 *        @include grid-column(3);
 *    }
 */
@mixin grid-column(
    $columns: 0,
    $gutter-left: $grid-columns-gutter,
    $gutter-right: $grid-columns-gutter,
    $amount: $grid-columns-amount,
    $workaround: false,
    $container-width: $site-width
) {
    /**
     * Magic part where the width is calculated
     */
    $grid-column: percentage($columns / $amount);

    float: left;
    position: relative;
    margin-bottom: percentage($grid-columns-gutter/strip-units($container-width)) * 2;
    padding-right: percentage($grid-columns-gutter/strip-units($container-width));
    padding-left: percentage($grid-columns-gutter/strip-units($container-width));

    @if $workaround == false {
        width: grid-column-width($columns, $amount);
    } @else {
        width: grid-column-width($columns, $amount) !important;
    }

    /**
     * Show in the terminal an warning if $columns is not set or 0
     */
    @if $columns == 0 {
        @warn '[grid-column]: $columns argument isn\'t set';
    }
}
