.header-stats {
  // border-bottom: 1px solid color(blue, 300);
  background-color: color(aqua, $alpha: 0.1);
  padding: cp(.5) cp(1);
  margin-left: -#{cm(1)};
  margin-right: -#{cm(1)};
  margin-top: -#{cm(1)};
  //border-radius: $border-radius;
  margin-bottom: cm(2);

  .statistics {
    width: 100%;
    display: flex;
    // margin: 0 -#{cm(.5)};

    app-stat-block {
      transform: scale(0.8);
      width: 200px;
      height: 220px;
      display: block;
      text-align: center;
      margin: 0 -12px;
      // margin: 0 cm(.5);
    }

    .panel {
      border: 0;
    }

    &__graph {
      width: 400px;
      height: 220px;
      padding: cp(1);
      margin-right: auto;
    }
  }
}
