.bg--white {
  background-color: color(white);
}

.bg--black {
  background-color: color(black);
}

.bg--todo {
  background-color: color(aqua);
}

.bg--priority {
  background-color: color(pink);
}

.bg--doing {
  background-color: color(aqua, 400);
}

.bg--verify {
  background-color: color(yellow);
}

.bg--done {
  background-color: color(green);
}

.bg--new {
  background-color: color(white);
}
