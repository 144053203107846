/**
 * Select dropdown styling
 */
select {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: inherit;
    font-size: rem(15);
    border: 0;
    outline: none;
    appearance: textfield;

    &::-ms-expand {
        display: none;
    }

    > option {
        color: color(black);
    }
}

/**
 * Disabled select option
 */
option:disabled {
    color: #ccc;
    font-style: italic;
}
