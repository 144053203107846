$define-breakpoint-debug: true !default;

@mixin define-breakpoint($name) {
    &::after {
        content: $name;
        @if $define-breakpoint-debug {
            z-index: 99999;
            display: block;
            position: fixed;
            bottom: rem(8);
            left: rem(8);
            padding: rem(8) rem(12);
            background: #000;
            color: #fff;
            opacity: .75;
            font-size: rem(13);
        } @else {
            display: none;
        }
    }
}
