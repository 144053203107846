.comment-status {
  display: flex;
  flex-flow: column;
  align-items: center;

  &__inner {
    background-color: color(black, $alpha: 0.05);
    display: inline-flex;
    padding: rem(5px) rem(10px);
    border-radius: rem(40px);
    align-items: center;
    margin: rem(10px) auto rem(5px);
    color: color(aqua, 500);
  }

  &__person {
    display: flex;
    align-items: center;
  }

  &__orb {
    margin-right: rem(5px);
  }

  &__time {
    font-family: $header-font-medium;
    font-size: rem(14px);
    color: color(aqua, 500);
    margin-bottom: rem(10px);
  }
}
