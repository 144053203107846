/* Append pseudo-classes to a selector(s).
*
* @argument {list | string} $inputs
*   A selector, or list of selectors, to apply the pseudo-class to.
*
* @argument {pseudo-class} $pseudo [null]
*   The pseudo-class to be appended.
*
* @return {list}
*
* @access private
*/
@function assign-inputs($inputs, $pseudo: null) {

    $list: ();

    @each $input in $inputs {
        $input: unquote($input);
        $input: if($pseudo, $input + ':' + $pseudo, $input);
        $list: append($list, $input, comma);
    }

    @return $list;
}
