form {
    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
        border-color: color(blue);
        button.ql-active {
            background: darken(color(blue), 15%);
            border-radius: 5px;
            color: white;
            .ql-stroke {
                stroke: rgba(white, 1);
            }
            .ql-fill {
                fill: rgba(white, 1);
            }
        }
    }
    .ql-toolbar.ql-snow {
        border-radius: 5px 5px 0 0;
        background-color: color(blue);
        .ql-fill {
            fill: white;
        }
        .ql-stroke {
            stroke: white;
        }
        button:hover {
            .ql-fill {
                fill: rgba(white, .8);
            }
            .ql-stroke {
                stroke: rgba(white, .8);
            }
        }
    }
    .ql-container.ql-snow {
        border-radius: 0 0 5px 5px;
    }
    .ql-editor {
        min-height: 80px;
    }
}
