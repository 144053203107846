 .page {
   &__lanes {
     margin-left: -#{cp(0.5)};
     margin-right: -#{cp(0.5)};
     min-width: 1200px;
     display: flex;
     align-content: stretch;
     min-height: 100vh;
   }

   &__lanes-lane {
     padding: 0 cp(0.5);
     flex: 0 0 25%;
     display: flex;
     flex-direction: column;
   }

   &__title {
     position: relative;
     padding: cp(1) cp(1) cp(0.5) 40px;
     border: 1px solid color(blue, 300);
     border-radius: $border-radius $border-radius 0 0;
     border-bottom: 0;
     margin-bottom: 0;
     color: color(blue);
     display: flex;
     justify-content: space-between;
     align-items: center;

     app-status-orb {
       position: absolute;
       transform: scale(.5);
       display: inline-block;
       left: 12px;
       top: 17px;
     }

     em {
       font-family: $header-font-medium;
       font-style: normal;
       font-weight: 300;
       font-size: 0.8em;
     }
   }

   &__list {
     border: 1px solid color(blue, 300);
     border-top: 0;
     height: 100%;
     border-radius: 0 0 $border-radius $border-radius;
     padding: cp(0.5) cp(1) cp(1);

     &-item {
       margin-bottom: cm(0.5);
       position: relative;
       overflow: hidden;
       border-radius: $border-radius/2;

       &:active {
         box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.20);
         transition: box-shadow .1s ease-in-out;
       }

       &:hover ::ng-deep .backlog-card {
         border: 1px solid color(blue, 300);
         transition: border .1s ease-in-out;
       }

       &:last-child {
         margin-bottom: 0;
       }
     }

     &-placeholder {
       position: relative;
       background-color: color(aqua, $alpha: 0.1);
       border-radius: $border-radius/2;
       min-height: 50px;
       margin-bottom: cm(0.5);

       .mat-icon {
         @include abs(50%, null, null, 50%);
         transform: translate(-50%, -50%);
         color: color(blue);
       }
     }
   }
 }
