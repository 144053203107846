
.color--white {
  color: color(white);
  fill: color(white);
}

.color--black {
  color: color(black);
  fill: color(black);
}

.color--todo {

}

.color--doing {

}

.color--verify {

}

.color--done {

}

.color--new {

}
