.notify {
  width: .875rem;
  height: .875rem;
  background: #cf522d;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #fff;
  transform: translateY(1px);
  box-shadow: 0 0 0 1px #cf522d, 0 0 5px #cf522d;
  animation: 750ms ease-in-out infinite alternate blink;
}
