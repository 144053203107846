/**
 * Clearfix placeholder
 */
%clearfix {
    @include clearfix;
}

/**
 * Clearfix placeholder on an :after element
 */
%clearfix-after {
    @include clearfix-pseudo(after);
}
