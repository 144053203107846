/*
@mixin text-overflow($lines: 3, $max-height: em(75)) {
    overflow: hidden;
    display: -webkit-box;
    max-height: $max-height;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}
*/
@mixin text-overflow($lines: 3, $max-height: rem(80)) {
    overflow: hidden;
    max-height: $max-height;

    &::after {
        @include visually-hidden;

        content: 'text-overflow:true';
    }
}
