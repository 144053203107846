/**
 * Form textarea
 */
textarea {
    // padding: 0;
    width: 100%;
    padding-top: cp(.5);
    min-height: 34*2;
    line-height: 24px;


    &:focus {
      // background-color: color(black, $alpha: .2);
    }
}
