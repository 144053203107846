.loading {
  @include fxd(0, 0, 0, 0);
  z-index: z-index(nav) + 10;
  background-color: inherit;
}

.loading__indicator {
  @include abs(50%, null, null, 50%);

  display: flex;
  position: absolute;
  width: 200px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  // margin-top: 70px;


  &.is--active {
      z-index: 40;
      display: flex;
  }

  &__item {
    display: inline-block;
    width: 32px;
    height: auto;
    transform-origin: center center;
    // margin-right: 5px;
    padding: 0 5px;
    // background-color: color(blue);
    svg {
      width: 100%;
      height: 100%;
      fill: color(blue);
    }
    .is--active & {
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;

      &.is--bounce1 {
        animation-delay: -.64s;
      }

      &.is--bounce2 {
        animation-delay: -.56s;
      }

      &.is--bounce3 {
        animation-delay: -.48s;
      }

      &.is--bounce4 {
        animation-delay: -.32s;
      }

      &.is--bounce5 {
        animation-delay: -.16s;
      }

      &.is--bounce6 {
        animation-delay: -.0s;
      }
    }
  }
}

@keyframes sk-bouncedelay {
  /* stylelint-disable selector-list-comma-newline-after */

  0%, 80%, 100% {
      /* stylelint-enable selector-list-comma-newline-after */
      transform: scale(0);
  }

  40% {
      transform: scale(1);
  }
}
