%heading {
    font-family: $header-font;
    // margin: 0 0 .3em;
    line-height: 1;
    transition: opacity .3s ease-in;
    margin-bottom: 1ex;
    font-weight: 700;
    text-transform: uppercase;
}

%h1 {
  font-size: rem(61);
  @include mappy-query(mobile) {
    font-size: rem(45);
  }
}

%h2 {
  font-size: rem(48);

  @include mappy-query(mobile) {
    font-size: rem(36);
  }
}

%h3 {
  font-size: rem(39);

  @include mappy-query(mobile) {
    font-size: rem(29);
  }
}

%h4 {
  font-size: rem(31);
  @include mappy-query(mobile) {
    font-size: rem(23);
  }
}

%h5 {
  font-size: rem(25);
  @include mappy-query(mobile) {
    font-size: rem(19);
  }
}

%h6 {
  font-size: rem(20);
}

