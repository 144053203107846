progress {
  appearance: none;
  height: 12px;
  border-radius: 5px;

   &::-webkit-progress-bar {
      background-color: color(black, $alpha: .4);
      border-radius: 6px;
   }

   &::-webkit-progress-value {
      background-color: color(black);
      border-radius: 6px;
   }
}
