/**
 * Convert pixels to ems
 * eg. for a relational value of 12px write em(12) when the parent is 16px
 * if the parent is another value say 24px write em(12, 24)
 */

$em-base: 16px !default;

@function em($pxval, $base: $em-base) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }
    @if not unitless($base) {
        $base: strip-units($base);
    }

    @return ($pxval / $base) * 1em;
}
