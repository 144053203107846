// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.


// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

/* For use in src/lib/core/theming/_palette.scss */


$afterburner-primary: mat.define-palette($md-fstrprimary, 500);
$afterburner-accent: mat.define-palette($md-fstrsecondary);
// The warn palette is optional (defaults to red).
$afterburner-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$afterburner-theme: mat.define-light-theme($afterburner-primary, $afterburner-accent, $afterburner-warn);
// -or- Can set colour by hex value too
$custom-background-color: #f4f7fd;
$background: map-get($afterburner-theme, background);
$background: map_merge(
  $background,
  (
    background: $custom-background-color,
  )
);
$theme: map_merge(
  $afterburner-theme,
  (
    background: $background,
  )
);

   // Input Theme

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($theme);

// $app-input-primary: mat-palette($md-fstrprimary, 900);
// $app-input-accent: mat-palette($md-fstrsecondary);
// $app-input: mat-light-theme($app-input-primary, $app-input-accent);
// @include mat-form-field-theme($app-input);
// @include mat-input-theme($app-input);

