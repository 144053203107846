/**
 * An inline panel which collapses on mobile devices
 */
@mixin panel-inline {
    position: relative;
    padding: rem(18);
    border-radius: 15px;

    /**
     * Mobile query
     * @query mobile
     */
    @include mappy-query(mobile) {
        overflow: hidden;
        max-height: rem(54);
        transition: max-height ($animation-time * 3) $animation-motion;
        will-change: max-height;

        > header {

            position: relative;
        }

        &.is--active {
            max-height: 1000px;
        }
    }
}

/**
 * The two states of the inline (collapsable) panel title
 */
@mixin panel-inline-title {
    font-size: rem(18);
    font-weight: 600;
    line-height: 25px;

    @include mappy-query(mobile) {
        font-size: rem(16);
        line-height: 19px;
    }
}

/**
 * Separator above the inline content
 */
@mixin panel-inline-content {
    margin-top: rem(18);
    padding-top: rem(18);
}
