/**
 * Base clearfix mixin
 */
@mixin clearfix($element: block) {
    display: #{$element};
    clear: both;
    width: 100%;
}

/**
 * Clearfix on pseudo element mixin
 */
@mixin clearfix-pseudo($pseudo: after) {
    display: block;

    &::#{$pseudo} {
        @include clearfix;

        display: block;
        content: '';
    }
}
