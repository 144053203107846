/**
 * Size functions off a sass map
 *
 * @see ../settings/_sizes.scss
 */

/*
 * Default size base variable
 */
$size-base: () !default;

/**
 * Calc-math base function
 *
 * @param {string} $type
 * @param {string} $size [0]
 *
 * @example calc-math(margin, $size);
 */
@function calc-math($type, $size) {
    $base: map-get($size-base, #{$type});
    $value: $base * $size;

    @if $size == null {
        $size: 1;
    }

    @if $base == null {
        @error '$`#{$type}` key not available in the $size-base map';
    }

    @return $value;
}

/**
 * Margin function
 *
 * @param {string} $size [0]
 *
 * @example cm(1);
 * @return {number} number or rem or em value
 */
@function cm($size: 0) {
    @return calc-math(margin, $size);
}

/**
 * Padding function
 *
 * @param {string} $size [0]
 *
 * @example cp(1);
 * @return {number} number or rem or em value
 */
@function cp($size: 0) {
    @return calc-math(padding, $size);
}
